

.modal-content {
    position: fixed;
    width: 100vw;
    height:100vh;
    top: 0;
    left: 0;
    overflow:scroll;
    display:block;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
    overscroll-behavior: none;
  }
  
  
  .modal {
    width: 70%;
    height: fit-content;
    background: white;
    z-index: 41;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: auto;
    margin-top: 5vmin;
    position: relative;
  }
  
  .modal .modal-section-top{text-align: right;}
  .modal .modal-section-top span{
    color:#3E90A6;
    font-size: 28pt;
    margin: 1rem;
    display: inline;
    cursor: pointer;
  }
  
  .modal .modal-section-body{
    color:#3E90A6;
    padding: 0 1rem 1rem 1rem;
    box-sizing: border-box;
  }
  
  .padding-0 .modal .modal-section-body{
    padding: 0;
  }
  

.modal-content.small .modal {
  
    width: 70vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    max-height: fit-content !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(0%, 25%);
    min-height: unset;
    
  }


.modal-section-body .center-content{
    display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      min-height: 100vh;
      box-sizing: border-box;
      padding: 2rem;
  }

  .modal-close{
    display: none;
  }
  


@media (max-width: 425px){
    .modal {
      width: 100%;
      margin-top: 0;
      /* min-height: 35vh; */
      min-height: 100vh;
    }
  }



.content-anhelo-modal .aside-rigth-ah{
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 1rem;
    width: 20vw;
  }
  
  
  .content-anhelo-modal {
    padding-top: 3rem;
    display: flex;
    flex-direction: row;
  }
  
  .content-anhelo-modal .aside-rigth-ah > img {
    margin:auto;
    border: 1px rgba(89, 76, 202, 0.3) solid;
    border-radius: 50px;
    height:100px;
    width: auto;
  }
  .content-anhelo-modal .aside-rigth-ah > span {
    font-size: 12pt;
  }
  
  .content-anhelo-modal .aside-rigth-ah > .luquid-content{
    transform: translate(8%, -40%);
  }
  