@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lato:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Heebo);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SimplonBP";
  src: url("https://happy-ardinghelli-e7e5a3.netlify.app/fuentes/SimplonBP-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SimplonBP";
  src: url("https://happy-ardinghelli-e7e5a3.netlify.app/fuentes/SimplonBP-Medium.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SimplonBPRegular";
  src: url("https://happy-ardinghelli-e7e5a3.netlify.app/fuentes/SimplonBPRegular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SimplonBPMono";
  src: url("https://happy-ardinghelli-e7e5a3.netlify.app/fuentes/SimplonBPMono-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Lato", sans-serif;
  overscroll-behavior: none;
}

.switch-wrapper {
  overflow: hidden;
}

.App {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: 15vw 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header header"
    "sidebar main";
}

.App.quest-content {
  grid-template-areas:
    "header header"
    "main main";
}

header {
  grid-area: header;
}
aside {
  grid-area: sidebar;
}

main {
  grid-area: main;
}

/*Header*/
header {
  min-height: 7vmin;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  background-color: rgb(45, 113, 138);

  display: grid;
  grid-template-columns: 13vw 1fr;
  z-index: 5;
  color: white;
}

header > div.aside-left {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

header > div.aside-left > span.fa {
  /* color: #B3CCE4; */
}

header > div.aside-left img {
  height: 3vmin;
  margin-left: 1rem;
}

header > div.aside-rigth {
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem 1rem;
  align-items: center;
}

header > div.aside-rigth .content-avatar {
  display: flex;
  align-items: center;
}

.content-avatar span:first-child {
  /* font-family: Montserrat; */
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
  /* color: #398599; */
  font-size: 14pt;
  font-weight: bold;
}
.content-avatar img.img-avatar {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  margin-left: 0.7rem;
}

/*end Header*/

/*Menu*/

aside {
  display: flex;
  flex-direction: column;
  /* font-family: Heebo; */
  /* font-family: SimplonBP; */
  font-family: SimplonBPRegular;
  /* font-family: SimplonBPMono; */
  z-index: 3;
  /* background-color: rgb(224, 224, 224);
  color: rgb(46,114,138); */
  background-color: #f2f2f2;
  color: rgb(46, 114, 138);
  /* color: white; */
}

aside > nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 2rem 1.3rem;
}

aside > nav span.caption {
  /* color: #C0BEC5; */
  margin-bottom: 0.3rem;
}

aside > nav > section.section-menu:first-child {
  margin-bottom: 2rem;
}

aside > nav > section.section-menu {
  margin-bottom: 2rem;
}
aside > nav span.caption {
  margin-bottom: 1rem;
  display: block;
  text-align: left;
  font-size: 15pt;
}
aside > nav a {
  text-decoration: none;
  /* color: #474857; */
  color: rgb(46, 114, 138);
  /* color: white; */
  display: block;
  margin-bottom: 0.3rem;
  font-size: 14pt;
}

aside > nav span.caption + a {
  margin-left: 0.7rem;
}

aside > nav a .fa {
  margin-right: 0.7rem;
  font-size: 13pt;
  /* color: #C0BEC5; */
  /* color: rgb(46,114,138); */
}
aside > nav a.active > span:last-child {
  /* color: #398599; */
  margin-right: 0.9rem;
  font-weight: bold;
}
aside > nav a.active .fa {
  /* display: none; */
}
/* 
aside > nav a.active > .fa{
  color: #7887C8;
} */

aside > footer {
  box-shadow: 0 -1px 0px rgba(0, 0, 0, 0.12), 0 0px 0px rgba(0, 0, 0, 0.24);
  flex-shrink: 1;
  height: 50px;
  /* color: #C0BEC5; */
  /* color: rgba(255,255,255,0.9); */
  color: rgb(46, 114, 138, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*end Menu*/

/*main*/
main {
  overflow-y: scroll;
  z-index: 1;
  color: #474857;
  /* color: #C0BEC5; */
}

main h1 {
  font-size: 22pt;
  /* color: #5C65AD; */
  color: #398599;
}
main h2 {
  font-size: 20pt;
  /* color: #5C65AD; */
  color: #398599;
}
main h3 {
  font-size: 18pt;
}
main h4 {
  font-size: 16pt;
}
main h5 {
  font-size: 14pt;
}
main h6 {
  font-size: 12pt;
}

.main-content {
  padding: 5vmin;
  box-sizing: border-box;
}

.section-main {
  margin-bottom: 5vmin;
}

/*end Main*/

/*satisfaction content*/

.top-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5vmin;
}
/**/

.top-content > div {
  padding: 1rem;
  padding-bottom: 3rem;
  background-color: rgb(239, 239, 239);
  box-sizing: border-box;
}

.top-content > div > h2 {
  margin-bottom: 3rem;
  color: rgb(135, 135, 135);
}
/**/

.top-content > div.metric-content {
  padding: 1rem;
  padding-bottom: 4rem;
}

.top-content > div.metric-content h2 {
  margin-bottom: 4rem;
  color: rgb(135, 135, 135);
}
.satisfaction-content {
  display: flex;
  flex-direction: column;
}

.satisfaction-content > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  /* grid-template-columns:  1fr 1fr 1fr ;
  grid-gap: 0.7rem; */
  /* width: 55%; */
}
.satisfaction-content > span {
  /* margin-bottom: 2rem; */
}

.satisfaction-content .satisfaction-content-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 30%;
  margin-top: 2rem;
  color: rgb(110, 110, 110);
  font-size: 2.1vmin;
  font-weight: bold;
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
}

.satisfaction-content .satisfaction-content-item span:last-child {
  /* color:#57B5CD; */
  /* color:#5C65AD; */
  font-size: 3.5vmin;
  margin-top: 0.5rem;
  color: rgb(37, 90, 96);
  font-family: Lato;
}

.satisfaction-content .satisfaction-content-item span:last-child > small {
  font-size: 2vmin;
  line-height: 1;
}

.satisfaction-content > span:last-child {
  flex-grow: 1;
}

.content-dummy {
  background-image: url("/dummy.jpg");
  height: 73%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f4f4f4;
}

/*end satisfaction content*


/*rotacion*/

.rotacion-content {
  background-color: rgb(235, 235, 235);
  padding: 1rem;
  padding-bottom: 3rem;
}
.rotacion-content h2 {
  margin-bottom: 3rem;
  color: rgb(135, 135, 135);
}

.rotacion-content > div > .recharts-wrapper {
  margin-top: 1.4rem;
}

.rotacion-content > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}

.rotacion-content > div.stats-content {
  /* display: flex;
  flex-direction: row; */
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 2rem;
}

.tab-content {
  flex-grow: 1;
  padding: 0 1rem;
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
}

.tab-header {
  /* margin-top: 1rem; */
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  color: rgb(109, 109, 109);
}
.tab-header span {
  /* line-height: 3; */
  padding-bottom: 0.7rem;
  flex-grow: 1;
  text-align: center;
  font-size: 2.3vmin;
  cursor: pointer;
}

span.active-tab {
  /* border-bottom:solid 1.3px #57B5CD; */
  border-bottom: solid 1.3px rgb(109, 109, 109);
  /* border-bottom:solid 1.3px #928fab; */
  font-size: 2.4vmin;
  font-weight: bold;
}

.content-body-horizontal {
  display: flex;
  flex-direction: row;
}

.content-prom {
  width: 100%;
}
.content-prom > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.content-prom > h4 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: rgb(53, 125, 114);
  text-align: center;
  font-size: 3vmin;
  /* font-weight: 100; */
  font-family: Lato;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
  /* 
  Montserrat
Lato
Heebo */
}
.content-prom > p {
  margin-top: 0;
  margin-bottom: 1.7rem;
  /* color:#354FB7; */
  text-align: center;
  font-family: Lato;
  /* font-family: SimplonBP; */
  font-weight: 100;
  font-size: 15pt;
  color: rgb(109, 109, 109);
  white-space: nowrap;

  /* color:#5C65AD; */
}

.content-prom > div > div {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  /* background-color: #57B5CD; */
  background-color: rgb(56, 128, 87);
  /* background-color: #928fab; */
}

.content-prom span {
  display: block;
  margin: auto;
  color: white;
}

.content-prom > div > div > span:first-child {
  font-size: 3.5vmin;
}

.content-prom > div > div > span:nth-child(2) {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 10pt;
  white-space: nowrap;
}

.content-prom > div > div > span:last-child {
  font-size: 3.5vmin;
  margin-top: 0.3rem;
  white-space: nowrap;
}

/*end rotacion*/

/*anhelos*/

/* .content-anhelos{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.content-anhelos > div{
  flex-grow: 1;
  width: 50%;
} */

.content-def-anhelo > span {
  margin-bottom: 0.5rem;
  display: block;
}

.content-anhelo-card {
  padding: 2rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  /* background-color: rgb(53, 79, 183, 0.7); */
  background-color: rgb(230, 236, 240);
  color: rgb(83, 83, 83);
  margin-bottom: 2rem;
  position: relative;
  text-align: center;
}

.content-anhelo-card img {
  width: 150px;
  display: block;
  margin: auto;
  border-radius: 78px;
  margin-bottom: 2rem;
}

.content-anhelo-card h3 {
  margin-top: 0;
  margin-bottom: 1.3rem;
  text-transform: uppercase;
  font-family: Lato;
  font-weight: bold;
  font-size: 18pt;
}
.content-anhelo-card h5 {
  margin-bottom: 1rem;
}

.content-anhelo-card p {
  margin-top: 0;
  width: 90%;
  line-height: 1.2;
  margin: auto;
}

.content-anhelo-card button {
  padding: 0.3rem 0.5rem;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  margin: auto;
  font-size: 14pt;
  display: block;
  margin-top: 1rem;
}

.luquid-content {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

.content-thumb-personas {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  padding-left: 1rem;
}

.content-thumb-personas > .thumb-persona {
  padding: 0.3rem;
  border: 1px rgba(89, 76, 202, 0.3) solid;
  border-radius: 5px;
}

.content-thumb-personas > .thumb-persona h4 {
  font-size: 14pt;
  margin: 0 0.3rem;
}

.content-thumb-personas > .thumb-persona span {
  font-size: 12pt;
  text-align: right;
  display: block;
  width: 100%;
}

/*end anhelos*/

/*Triggers*/

.content-triggers {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.content-triggers > div {
  flex-grow: 1;
}
.content-triggers > div:first-child {
  flex-shrink: 1;
  flex-grow: 0;
}

.content-table-trigger {
  padding: 0 2rem;
  box-sizing: border-box;
}
.content-table-trigger table {
  width: 100%;
  font-size: 12pt;
  border-collapse: collapse;
}

.content-table-trigger table thead th:first-child {
  text-align: left;
}
.content-table-trigger table thead th {
  border-bottom: 1.7px #6d6d6d solid;
  padding: 0.7rem 1rem;
}

.content-table-trigger table td {
  padding: 0.5rem;
  vertical-align: middle;
  line-height: 1.5;
  /* text-align: center; */
}
.content-table-trigger table td > * {
  display: inline-block;
}
.content-table-trigger table td .small-color {
  width: 4px;
  height: 1rem;
  display: inline-block;
  margin-right: 0.5rem;
  transform: translateY(3.5px);
}

.content-table-trigger table td.type-numeric {
  text-align: center;
}

.content-detail-trigger {
  padding-left: 2rem;
  color: rgb(60, 60, 60);
}

.content-detail-trigger h3 {
  margin-top: 0;
  color: #6d6d6d;
}

.content-detail-trigger > div {
  padding: 1rem;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
  background-color: rgb(224, 224, 224);
  font-family: Lato;
  font-weight: 300;
}

.content-detail-trigger > div h5 {
  margin: 0;
  font-weight: 400;
  font-size: 16pt;
  margin-bottom: 0.3rem;
}
.content-detail-trigger > div p {
  padding: 0;
  margin: 0;
  font-size: 11pt;
}

.content-actors-trigger {
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
}

.content-actor-thumb-trigger {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.content-actor-thumb-trigger > .actor-thumb-trigger {
  padding: 1rem;
  position: relative;
  /* color: rgb(86, 181, 205); */
  color: white;
  background-color: rgb(135, 135, 135);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* color: white; */
  /* border-radius: 10px; */
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
}

.actor-thumb-trigger h5 {
  margin: 0;
}

.actor-thumb-trigger span.lifetime-burble.danger {
  background-color: rgb(225, 39, 39);
}

.actor-thumb-trigger span.lifetime-burble.warning {
  background-color: rgb(230, 92, 40);
}

.actor-thumb-trigger span.lifetime-burble.success {
  background-color: rgb(88, 153, 72);
}

.actor-thumb-trigger span {
  /* color : #5C65AD;
color : #979AB4; */
  /* color : #8885A3; */
  /* color : #BDC3DE;
color : #57B5CD; */
}
.actor-thumb-trigger span.lifetime-burble {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 50px;
  height: 50px;
  line-height: 1;
  background-color: rgb(86, 181, 205);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 25px;
  border: 0.7px solid white;
}

.content-breadcrumb-trigger {
  margin-bottom: 3rem;
}

.breadcrumb-trigger {
  font-size: 18pt;
  font-family: Lato;
  color: #6d6d6d;
  /* font-weight: bolder; */
  /* font-family: Heebo; */
}

.breadcrumb-trigger:last-child {
  line-height: 1.1;
  /* border-bottom: 1.7px solid #8885A3; */
  font-weight: bold;
}

.breadcrumb-trigger .fa {
  margin: 0 0.7rem;
}
.content-actors-detail {
  display: flex;
  flex-direction: row;
}

.content-actors-detail > .actor-detail-info {
  padding: 0 2rem;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: grid;
}

.actor-detail-info span {
  text-align: center;
  color: rgb(56, 128, 87);
  font-family: Lato;
}
.actor-detail-info h5 {
  font-size: 18pt;
  /* font-weight: 400; */
  margin: 0;
  text-align: center;
  color: rgb(56, 128, 87);
  font-family: Lato;
}

.actor-detail-info > img {
  position: absolute;
  width: 75px;
  top: 0;
  right: 0;
  transform: translateY(-50%);
}

.actor-detail-info h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
  font-family: SimplonBPMono;
  letter-spacing: 5px;
  color: rgb(56, 128, 87);
}
.content-skills {
  max-width: 100%;
  overflow: hidden;
  height: 400px;
}

.content-skills > svg {
  transform: translateX(-7%);
}

.content-metrics {
  padding: 0 0.5rem;
}

.content-metrics > .metrics-item {
  /* color: rgb(86, 181, 205); */
  /* color: white; */
  border-radius: 10px;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 1rem;
  background-color: rgb(193, 193, 193);
}

.content-metrics > .metrics-item span {
  align-self: center;
  text-align: center;
  color: white;
}

.content-metrics > .metrics-item span:first-child {
  font-size: 18pt;
  font-family: Montserrat;
}

.content-metrics > .metrics-item span:first-child > span {
  font-size: 12pt;
  /* color : #8885A3; */
  display: block;
}

.content-metrics > .metrics-item span:last-child {
  font-size: 15pt;
  font-family: Montserrat;
  /* color : #8885A3; */
}

/* color : #8885A3; */

/*end Triggers*/

/*capacity*/
.capacity-content {
  background-color: rgb(235, 235, 235);
}
/*end capacity*/

/**/
.mot-person-count {
  font-size: 22pt;
  margin-bottom: 1rem;
  display: block;
}
.mot-person-count small {
  font-size: 13pt;
}
.table-raw-mot {
  width: 80%;
  border-spacing: inherit;
  border-left: 1px solid rgba(57, 133, 153, 0.3);
  padding-left: 1rem;
}
.table-raw-mot tbody tr > td {
  padding: 0.5rem;
  padding-top: 0.3rem;
  /* border-bottom: 1px solid rgba(71, 72, 87, 0.3); */
  font-family: Montserrat;
  /* font-family:Lato; */
  /* font-family:Heebo;  */
}

.table-raw-mot tbody > tr:first-child td {
  padding-top: 0.5rem;
}

.table-raw-mot tbody tr > td:nth-child(2) {
  color: rgba(255, 183, 0, 0.8);
}
.table-raw-mot tbody tr > td:first-child {
  color: rgba(42, 103, 236, 0.6);
}

.table-raw-mot tbody tr > td:first-child {
  width: 30%;
  font-size: 12pt;
}

.table-raw-mot tbody tr > td:nth-child(2) {
  width: 50%;
  font-size: 10pt;
}

.table-raw-mot tbody tr > td:last-child {
  width: 20%;
  text-align: center;
  font-size: 12pt;
}

.table-raw-mot tbody tr > td:last-child small {
  font-size: 9pt;
  display: block;
  /* color: #DD2B5F */
}

.content-anhelo-interest {
  text-align: left;
  margin-top: 1rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.content-anhelo-interest > .item-anhelo-interest:first-child {
  border-left: unset;
}

.item-anhelo-interest {
  margin-bottom: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  /* padding-left: 0.5rem;  */
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  width: 100%;
}
.item-anhelo-interest > span {
  display: block;
  font-size: 15pt;
  margin-bottom: 0.3rem;
}
.item-anhelo-interest small {
  display: block;
}

/**/

.content-mot-prof {
  padding: 1rem;
  padding-bottom: 3rem;
  background-color: rgb(235, 235, 235);
  box-sizing: border-box;
}

.content-mot-person {
  /* padding: 2rem; */
  width: 100%;
  box-sizing: border-box;
}

.content-mot-person h4 {
  margin: 0.4rem 0;
}

.item-mot-person {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  /* grid-gap: 2rem; */

  margin-bottom: 2rem;
  padding: 1rem;
  box-sizing: border-box;
  color: white;
  /* background-color: #5C65AD; */
  background-color: #398599;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
}

.item-mot-person h4 {
  margin-bottom: 1.7rem;
  grid-column: 1 / 3;
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  font-size: 20pt;
  /* width: 100%; */
}
.item-mot-person > div:last-child {
  grid-column: 1 / 3;
}
.item-mot-person > div:last-child h6 {
  text-align: center;
  letter-spacing: 5px;
}

.item-mot-person h6 {
  margin: 0;
  /* font-family: SimplonBPMono; */
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  font-size: 18pt;
}

.item-mot-person > span {
  color: white;
  background-color: #50aba8;
  font-size: 20pt;
  text-align: center;
  display: block;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
}
.item-mot-person > span small {
  display: block;
  clear: both;
  font-size: 14pt;
}

.item-mot-person > div {
  padding: 0.7rem;
}

.item-mot-person > div .recharts-wrapper {
  margin: auto;
}

.tool-tip-anh {
  padding: 0.5rem 0.5rem;
}

.tool-tip-anh h4 {
  margin: 0;
  font-size: 13pt;
  margin-bottom: 0.3rem;
}
.tool-tip-anh > div span {
  display: inline-block;
}
.tool-tip-anh div span:last-child {
  margin-left: 1rem;
  font-size: 12pt;
}

.arrow_box {
  padding: 0.3rem;
}

.arrow_box > span {
  display: block;
  clear: both;
}
.arrow_box > span:first-child {
  font-size: 12pt;
}

.content-items-actors {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  color: #398599;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.item-actor-anhelos {
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: rgb(26, 65, 72);
  position: relative;
}

.item-actor-anhelos h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
}

.item-actor-anhelos span {
  display: block;
  text-align: center;
}

.item-actor-anhelos h6 {
  font-size: 12pt;
  font-family: SimplonBP;
  margin: 0;
  margin-bottom: 0.3rem;
  justify-self: start;
}

.item-actor-anhelos h6 + span {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 18pt;
  text-align: center;
}
.item-actor-anhelos h6 + span small {
  font-size: 12pt;
  color: rgb(59, 59, 59);
}

.item-actor-anhelos > div:first-child > div {
  color: rgb(59, 59, 59);
}
.item-actor-anhelos > div:first-child {
  flex-shrink: 0;
  flex-grow: 1;
}
.item-actor-anhelos > div {
  /* width: 30%; */
  flex-shrink: 1;
  flex-grow: 0;
}

.item-actor-anhelos > div:first-child {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.item-actor-anhelos > div:nth-child(2) {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.stats-content {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5vmin;
  padding: 0 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.stats-content > div {
  /* color: #5C65AD; 398599*/
  /* background-color: #5C65AD; */
  padding: 1rem;
  text-align: center;
  color: rgb(54, 126, 112);
}

.stats-content h2 {
  /* color: white; */
  margin: 0;
  font-size: 16pt;
  color: rgb(54, 126, 112);
}

.stats-content > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(54, 126, 112);

  /* font-family: Montserrat; */
  font-family: SimplonBP;
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
}

.stats-content > div > div > span:first-child {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  font-size: 30pt;
}
.stats-content > div > div > span:last-child {
  font-weight: lighter;
  font-size: 20pt;
  font-family: Lato;
}

.App.quest-content main > section {
  width: 70%;
  margin: auto;
}

/*questions*/

.App.quest-content main > section.section-quest > p:first-child {
  font-size: 16pt;
  margin: 1rem 0 2rem 0;
}
.answer-content input[type="text"],
.answer-content input[type="number"],
.answer-content textarea {
  width: 100%;
  border: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0.3rem 0.5rem;
  font-size: 14pt;
}

.section-quest-item {
  margin-bottom: 2rem;
}

.section-quest section:last-of-type {
  margin-bottom: 0rem;
}

.content- > .section-quest-item::after {
  content: "";
  position: absolute;
  width: calc(100% - 2rem);
  border-bottom: 1px solid white;
  bottom: -2rem;
  border-radius: 6px;
}

.section-quest-item {
  margin-bottom: 4rem;
  padding: 0 1rem;
  font-family: Heebo;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.section-quest-item p {
  margin: 0;
  margin-bottom: 0.7rem;
  line-height: 1.4;
}
/* .answer-content input[type='text'], .answer-content input[type='number'], .answer-content textarea{
  color: #143845;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  border: none;
  font-size: 12pt;
  background-color: rgba(255, 255, 255, 0.9);
  
  width: 100%;
  box-sizing: border-box; 
}*/

.answer-content input[type="radio"] {
  visibility: hidden;
  position: absolute;
}

.answer-content.answer-content-vertical label,
.answer-content.answer-content-horizontal label {
  padding: 0.3rem 0.5rem;
}

.answer-content label {
  line-height: 1.3;
  position: relative;
}

.answer-content label.active-radio {
  border: #398599 1px SOLID;
}

.grid-content.single span {
  position: relative;
  padding-left: 1rem;
}

.grid-content.single span.active::before,
.answer-content.answer-content-vertical label.active-radio.input-radio::before {
  content: "\2B24";
}

.grid-content.single span::before,
.answer-content.answer-content-vertical .input-radio::before {
  content: "\25EF";
  display: block;
  font-size: 7pt;
  position: absolute;
  top: 50%;
  /* left: 14px; */
  transform: translate(-1.1rem, -50%);
}

.answer-content.answer-content-vertical label {
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.answer-content.answer-content-vertical {
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: column;
}

.answer-content.answer-content-horizontal {
  display: flex;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-around;
}

.answer-content .input-range__track--active {
  background: #00aba8;
}

.answer-content .input-range__slider {
  background: #00aba8;
  border: 1px solid #00aba8;
}

.section-quest button {
  margin: 4rem auto;
  color: #00aba8;
  border: none;
  font-size: 16pt;
  display: block;
  border: 1px solid #00aba8;
  padding: 1rem;
  border-radius: 5px;
  width: 40%;
}

/*fin questions*/

.content-login {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
}

.content-login .brand-content {
  background-position: top;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.content-login .brand-content > div {
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-login .brand-content img {
  max-height: 10vmin;
  width: auto;
}

.content-login .form-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.content-login .form-content > div {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 2rem;
  width: 75%;
  box-sizing: border-box;
}
.content-login .form-content .form-section h2 {
  color: #398599;
  box-sizing: border-box;
}

.form-section {
  box-sizing: border-box;
  position: relative;
}

.form-section input {
  border: none;
  width: 100%;
  padding: 0.5rem 2rem;
  box-sizing: border-box;
  font-size: 16pt;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: #398599;
}
.form-section > span {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: rgba(51, 51, 51, 0.5);
  font-size: 14pt;
}

.content-login .form-content > div button {
  padding: 0.7rem 1rem;
  font-size: 14pt;
  border-radius: 40px;
  width: 200px;
  color: white;
  margin: auto;
  display: block;
  margin-top: 3rem;
  border: unset;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: left;
}

/*time Line*/

.time-line-ctnr {
  margin-top: 2rem;
}
.time-line-ctnr .time-line {
  position: relative;
  margin: 0 0 0px 0;
  padding: 0;
  list-style: none;
}
.time-line-ctnr .time-line:before {
  content: "";
  position: absolute;
  /* top: 0; */
  top: 60px;
  /* bottom: -10px; */
  bottom: 10px;
  width: 2px;
  background: #2d718a;
  left: 32px;
  border-radius: 2px;
}
.time-line-ctnr .time-line > .time-label > span {
  font-weight: 600;
  padding: 7px 18px;
  display: inline-block;
  background-color: #388057;
  color: #fff;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 400;
  font-family: Lato;
  width: 100px;
  text-align: center;
}

.time-line-ctnr .time-line > li {
  position: relative;
  margin-bottom: 10px;
  margin-right: 0;
}
.time-line-ctnr .time-line > li > .time-line-item {
  /* -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); */
  border-radius: 3px;
  /* background: #fff; */
  color: #6d6d6d;
  margin-right: 15px;
  position: relative;
  margin-left: 50px;
}
.time-line-ctnr .time-line > li > .time-line-item > .time-line-header,
.time-line-ctnr .time-line > li > .time-line-item .time {
  font-size: 14px;
  padding: 6px 10px;
}
.time-line-ctnr .time-line > li > .time-line-item .time {
  font-size: 12px;
  color: #999;
  float: right;
}
.time-line-ctnr .time-line > li > .time-line-item .time-line-header {
  margin: 0;
  /* color: #555;
  border-bottom: 1px solid #f4f4f4; */
  line-height: 1.1;
}
.time-line-ctnr .time-line > li > .fa {
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  color: #666;
  background: #2d718a;
  border: 2px solid #2d718a;
  left: 28px;
  top: 9px;
  display: inline-block;
  box-sizing: border-box;
}

.section-main.four-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 5vmin;
}

/*Profile*/
.profile-content {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
}

.company-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.company-content > {
  flex-grow: 0;
}
.company-content img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid white;
}
.company-content > div:first-child {
  margin: 0 auto;
}
.company-content > div:nth-child(2) {
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.company-content > div:nth-child(2) > div {
  margin-bottom: 0.5rem;
}

.company-content > div:nth-child(2) strong {
  display: block;
  font-size: 14pt;
  color: #398599;
  border-bottom: 0.7px solid rgba(41, 93, 123, 0.7);
  padding-bottom: 0.1rem;
  margin-bottom: 0.3rem;
}
.company-content > div:nth-child(2) span {
  display: block;
  width: 100%;
  text-align: right;
  line-height: 1.5;
  font-size: 13pt;
}

.company-content h6 {
  margin: 1.5rem 0 1rem 0;
  font-size: 16pt;
  color: #6d6d6d;
}

.profile-content .colaborator-content {
  background-color: rgb(230, 236, 240);
  padding: 1rem;
}

/*
.content-form.upload-question > .content-input-callback span{
  font-size: 13pt;
}

.content-form.upload-question > .content-input-file span a,
.content-form.upload-question > .content-input-file  .input-file-upload + label,
.content-form.upload-question > .content-input-callback button{
  padding: 0.5rem 0.7rem;
  font-size: 13pt;
  margin-left: 2rem;
}

.content-form.upload-question table{
  width: 100%;
  font-size: 10pt;
}

.content-form.upload-question > .content-input-file span a{
  font-weight: 700;
  color: white;
  background-color: #295d7b;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}
*/

.content-form.upload-user {
  grid-column: 1 / 4;
}

.content-form.upload-user table {
  margin-top: 1rem;
  width: 100%;
}

.content-form.upload-user table tbody {
  color: #6d6d6d;
  font-size: 11pt;
  text-align: center;
}
.content-form.upload-user table tbody td {
  padding: 0.5rem 0;
}

.content-form.upload-user table tbody tr.checked {
  color: #f3ae28;
}

.content-form.upload-user > div {
  display: flex;
  justify-content: space-between;
}

.content-form.upload-user > div.content-input-file {
  display: flex;
  justify-content: flex-end;
}

.content-form.upload-user > div.content-input-callback {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.content-form.upload-question > .content-input-file,
.content-form.upload-question > .content-input-callback {
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;
  margin-bottom: 14px;
}
.content-form.upload-question > .content-input-callback span {
  font-size: 13pt;
}

.content-form.upload-question > .content-input-file span a,
.content-form.upload-question > .content-input-file .input-file-upload + label,
.content-form.upload-question > .content-input-callback button {
  padding: 0.5rem 0.7rem;
  font-size: 13pt;
  margin-left: 2rem;
}

.content-form.upload-question table {
  width: 100%;
  font-size: 10pt;
}

.content-form.upload-question > .content-input-file span a {
  font-weight: 700;
  color: white;
  background-color: #295d7b;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.content-input-callback button {
  font-size: 1.25em;
  font-weight: 400;
  color: white;
  background-color: #295d7b;
  padding: 0.7rem 1rem;
  margin: unset;
  margin-left: 0.5rem;
  border-radius: unset;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.content-input-callback button:hover {
  background-color: #f3ae28;
  transition: color 1.7s ease;
}

.content-input-callback span {
  color: #265b61;
  font-size: 18pt;
}

.input-file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.content-form.upload-user > .content-input-file span a {
  text-decoration: none;
  margin-right: 10px;
}

.content-form.upload-user > .content-input-file span a,
.input-file-upload + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: #295d7b;
  display: inline-block;
  padding: 1rem;
}

.content-form.upload-user > .content-input-file span a:hover,
.input-file-upload:focus + label,
.input-file-upload + label:hover {
  background-color: #f3ae28;
  transition: color 1.7s ease;
}
.content-form.upload-user > .content-input-file span a,
.input-file-upload + label {
  cursor: pointer; /* "hand" cursor */
}

.input-file-upload:focus + label {
  outline: 1px dotted #295d7b;
  outline: -webkit-focus-ring-color auto 5px;
}

.remove-item-actor:hover,
.admin-item-actor:hover {
  background-color: #f3ae28;
}

.remove-item-actor {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  color: white;
  background-color: #265b61;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.admin-item-actor {
  position: absolute;
  top: 0;
  right: 40px;
  width: 30px;
  height: 30px;
  color: white;
  background-color: #265b61;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.company-content-image{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*Profile End*/

/*feedback*/
section.content-feedback {
  padding: 0;
}

section.content-feedback .main-top-content {
  padding: 1.5rem 1rem;
  background-color: rgba(45, 113, 138, 0.9);
  border: unset;
  outline: unset;
}

section.content-feedback .main-top-content h2 {
  margin: 0;
  color: white;
  font-size: 16pt;
}

section.content-feedback .content-contexts {
  padding: 2rem 0;
  box-sizing: border-box;
}

section.content-feedback .context-item > h3 {
  padding: 0 3rem;
  line-height: 1;
}

section.content-feedback .context-item > h3 small {
  font-size: 11pt;
  color: #f3ae28;
}

section.content-feedback .context-item .context-category-content {
  overflow-x: scroll;
  width: 100%;
  padding: 1rem 0;
  background-color: rgba(41, 93, 123, 0.7);
  display: flex;
  margin-bottom: 4rem;
}

.context-category-item {
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(26, 65, 72);
  position: relative;
  min-width: 20vmin;
  margin-left: 5vmin;
}

section.content-feedback .context-item .context-category-content {
  margin-right: 5vmin;
}

.context-category-item h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
  line-height: 1;
}

.context-category-item h4 small {
  font-size: 11pt;
  /* color: #f3ae28; */
  /* color: rgb(224, 224, 224); */
  color: #265b61;
}

.context-category-item span {
  display: block;
  text-align: center;
  color: rgb(59, 59, 59);
}
/**/

.content-context-detail {
  padding: 0 5rem;
  display: flex;
  flex-direction: column;
}

.filter-context-detail {
  display: flex;
  width: 100%;
}
.filter-context-detail > .context-filter-content {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.filter-context-detail > .context-filter-content h4 {
  width: 100%;
}

.filter-context-detail > .context-sort-content {
  flex-shrink: 1;
  width: 22vmin;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.context-filter-item {
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 200px;
}

.context-filter-content .context-filter-item {
  margin-right: 2rem;
}

.context-filter-item select {
  z-index: 2;
  border: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 12pt;
  width: 100%;
  line-height: 1.7;
  padding: 0 0.5rem;
  border-bottom: 1px solid rgb(224, 224, 224);
  border-radius: 0;
  /* color: #d6d6d6; */
  color: #265b61;
}

.context-filter-item select + span {
  position: absolute;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.main-context-detail {
  display: flex;
  flex-direction: column;
}
.main-context-detail > div:first-child {
  margin-top: 2rem;
}

.main-context-detail > div {
  margin-top: 1rem;
}

.main-context-detail .context-detail-indicators-content {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 1rem;
  /* grid-template-rows: auto; */
}
.main-context-detail .context-detail-indicators-content > div {
  background-color: rgb(235, 235, 235);
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
}

.main-context-detail .context-detail-indicators-content h4 {
  margin: 0;
}

.context-detail-feedback-content > div {
  position: relative;
  background-color: rgb(235, 235, 235);
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
}

.context-detail-feedback-content > div > div {
  position: absolute;
  top: 0;
  right: 0;
}

.context-detail-feedback-content > div {
  position: relative;
  background-color: rgb(235, 235, 235);
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
}

.context-detail-feedback-content > div span {
  padding: 0 0.5rem;
  line-height: 1.5;
  margin-top: 0.3rem;
  margin-right: 0.5rem;
  display: inline-block;
}

.label-team {
  background-color: #295d7b;
  color: white;
}

.label-category {
  background-color: #f3ae28;
  color: white;
}

.cssdf {
  color: #f3ae28;
  color: #589948;
  color: #388057;
  color: #295d7b;
  color: #265b61;
}

.progress-circle {
  transform: rotate(-90deg);
  fill: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-value-circle {
  stroke: rgb(44, 112, 137);
  /*animation: progress 2s infinite alternate;*/
}

.content-circle-progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 21pt;
  font-weight: 700;
  /* font-family: Montserrat; */
  font-family: Lato;
  /* font-family: Heebo; */
  /* font-family: SimplonBP; */
  /* font-family: SimplonBPRegular; */
  /* font-family: SimplonBPMono; */
  color: rgb(66, 157, 149);
}
.content-circle-progress small {
  font-weight: 300;
}



.modal-content {
    position: fixed;
    width: 100vw;
    height:100vh;
    top: 0;
    left: 0;
    overflow:scroll;
    display:block;
    background-color: rgba(0,0,0,0.4);
    z-index: 1000;
    overscroll-behavior: none;
  }
  
  
  .modal {
    width: 70%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: white;
    z-index: 41;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: auto;
    margin-top: 5vmin;
    position: relative;
  }
  
  .modal .modal-section-top{text-align: right;}
  .modal .modal-section-top span{
    color:#3E90A6;
    font-size: 28pt;
    margin: 1rem;
    display: inline;
    cursor: pointer;
  }
  
  .modal .modal-section-body{
    color:#3E90A6;
    padding: 0 1rem 1rem 1rem;
    box-sizing: border-box;
  }
  
  .padding-0 .modal .modal-section-body{
    padding: 0;
  }
  

.modal-content.small .modal {
  
    width: 70vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
    top: 50%;
    left: 50%;
    transform: translate(0%, 25%);
    min-height: unset;
    
  }


.modal-section-body .center-content{
    display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      min-height: 100vh;
      box-sizing: border-box;
      padding: 2rem;
  }

  .modal-close{
    display: none;
  }
  


@media (max-width: 425px){
    .modal {
      width: 100%;
      margin-top: 0;
      /* min-height: 35vh; */
      min-height: 100vh;
    }
  }



.content-anhelo-modal .aside-rigth-ah{
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 1rem;
    width: 20vw;
  }
  
  
  .content-anhelo-modal {
    padding-top: 3rem;
    display: flex;
    flex-direction: row;
  }
  
  .content-anhelo-modal .aside-rigth-ah > img {
    margin:auto;
    border: 1px rgba(89, 76, 202, 0.3) solid;
    border-radius: 50px;
    height:100px;
    width: auto;
  }
  .content-anhelo-modal .aside-rigth-ah > span {
    font-size: 12pt;
  }
  
  .content-anhelo-modal .aside-rigth-ah > .luquid-content{
    transform: translate(8%, -40%);
  }
  
